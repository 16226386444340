import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enGb from './locales/en-GB/translation.json';
import svSe from './locales/sv-SE/translation.json';

import config from './i18n-config';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enGb,
      },
      sv: {
        translation: svSe,
      },
    },

    fallbackLng: config.defaultLang.slice(0, 2),
    supportedLngs: config.langs.map((lang) => lang.slice(0, 2)),
    nonExplicitSupportedLngs: false,
    lowerCaseLng: true,
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
