const config = require('../i18n-config');

exports.getPathPrefix = (lang) => {
  if (
    (lang === config.defaultLang || lang === config.defaultLang.slice(0, 2)) &&
    !config.useDefaultLangInUrl
  ) {
    return '/';
  }

  const urlLang = config.shortenUrlLangs ? lang.slice(0, 2) : lang;

  return `/${urlLang}/`;
};
