const { getPathPrefix } = require('./i18n');
const { useDefaultLangInUrl } = require('../i18n-config');

const enGb = require('../locales/en-GB/translation.json');
const svSe = require('../locales/sv-SE/translation.json');

const translations = {
  'en-gb': enGb,
  'sv-se': svSe,
};

module.exports = (doc, isTemplate = false) => {
  let type;
  let lang;
  let prefix;
  let slug;

  if (isTemplate) {
    type = doc.type;
    lang = doc.lang;
    prefix = `/:lang${useDefaultLangInUrl ? '' : '?'}/`;
    slug = ':uid';
  } else {
    type = doc.type || doc._meta.type; // eslint-disable-line no-underscore-dangle
    lang = doc.lang || doc._meta.lang; // eslint-disable-line no-underscore-dangle
    prefix = lang ? getPathPrefix(lang) : '/';
    slug = doc.uid || doc._meta.uid; // eslint-disable-line no-underscore-dangle
  }

  switch (type) {
    case 'partners':
    case 'articles':
    case 'products':
    case 'projects':
    case 'clients':
    case 'contact':
    case 'page':
      return `${prefix}${slug}`;
    case 'product':
      return `${prefix}${translations[lang].permalinkProducts}/${slug}`;
    case 'project':
      return `${prefix}${translations[lang].permalinkProjects}/${slug}`;
    case 'article':
      return `${prefix}${translations[lang].permalinkArticles}/${slug}`;
    case 'client':
      return `${prefix}${translations[lang].permalinkClients}/${slug}`;
    default:
      return prefix;
  }
};
