/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable import/prefer-default-export */
import { registerLinkResolver } from '@theprepgroup/gatsby-source-prismic-graphql';
import linkResolver from './src/utils/linkResolver';
import wrapWithTheme from './src/wrapWithTheme';

registerLinkResolver(linkResolver);

export const wrapRootElement = wrapWithTheme;
